html{
  // background-image: url(../images/background.jpg);
  // background-repeat: no-repeat;
  // background-position: top center;

}
// .has-mobile-nav body.user_mode {
//    padding-top: 10px;
// }

.user_mode #siteContainer {
   padding: 0;

  //  .col-md-6 {
  //     width: 50%;
  //     float: left;
  //  }

}

.user_mode #siteContainer .yieldPageContent {
   padding-top: 0;
}


.user_mode .splash-banner {
   padding-top: 73px;
}

.user_mode .splash-photo {
   .heroPhotoElement {
      padding: 0;
      margin: 0;
      position: relative;
      z-index: 100;

      img {
         transition: filter .5s;
         position: relative;
         z-index: 0;
      }

      a:hover {
         text-decoration: none;
         color: #057509;

         p {
            color: #057509;
         }

         img {
            filter: drop-shadow(0 0 15px white)
         }
      }

   }
}

.user_mode .splash-buttons a img {
   transition: opacity .5s ease-in-out;

   &:hover {
      opacity: .8;
   }
}

.user_mode .splash-bar {

   display: none;
}
// Tablet
@media only screen and (min-width: 768px) {

   .has-mobile-nav body.user_mode {
      padding-top: 44px;
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 28px;
   }

   .user_mode .splash-banner {
      padding-top: 304px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 75px;
   }
}
// Desktop
@media only screen and (min-width: 1024px) {

   .user_mode #siteContainer {
      max-width: 1200px;

      .splash-buttons .col-md-6 {
         .heroPhotoElement {
            max-width: 478px;
         }

         .right .heroPhotoElement {
            float: right;
         }
      }
   }

   .user_mode #siteContainer .yieldPageContent {
      padding-top: 20px;
   }

   .user_mode .splash-banner {
      padding-top: 267px;
   }

   .user_mode footer.snFooterContainer {
      margin-top: 0;
   }
}

html:not(.collapsed-mobile-nav):not(.has-sub-nav) .site-background{
  margin-top: 40px !important;
}
html:not(.collapsed-mobile-nav):not(.has-sub-nav).nav-fixed .site-background{
  // margin-top: 0;
}
.splash-header{
  .pageElement{
    margin: 0;
  }
  img {
    width: auto;
    max-width: 100%;
  }
}
.user_mode #siteContainer .yieldPageContent{
  padding-top: 0px !important;
}

@media screen and (max-width: 1024px){
  .site-background {
      background-size: auto 100%;
  }
}
.padding{
  padding-top: 60px;
  padding-bottom: 60px;
}
.inset{
  max-width: 840px;
  margin-left: auto;
  margin-right: auto;
}
.splash-link .linkElement h4 a{
  &:before, &:after{
    display: none;
  }
    background-color: #1933a0;
    border: 2px solid white;
    box-shadow: inset 0px 5px 25px -5px black;
    padding: 20px;
    text-align: center;
    font-size: 40px;
    font-weight: 600;
}